var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("异常件上架")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      inline: true,
                      rules: _vm.validateForm,
                      model: _vm.searchForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "运单号", prop: "waybill" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "运单号" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.inShelfPackage.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.searchForm.waybill,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "waybill", $$v)
                            },
                            expression: "searchForm.waybill",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "货架号", prop: "shelf_code" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "货架号" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.inShelfPackage.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.searchForm.shelf_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "shelf_code", $$v)
                            },
                            expression: "searchForm.shelf_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "z-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.inShelfPackage },
                          },
                          [_vm._v("上架")]
                        ),
                        _vm.isWechat
                          ? _c(
                              "z-button",
                              {
                                attrs: { type: "default" },
                                on: { click: _vm.scanBarcode },
                              },
                              [_vm._v("扫码")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.codesList },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "waybill", label: "运单号" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "shelf_code", label: "货架号" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "ctrl-btn" }, [
        _c("div", { staticClass: "total" }, [
          _vm._v("共计处理：" + _vm._s(_vm.codesList.length || "-") + "个包裹"),
        ]),
      ]),
      _c("audio", {
        ref: "audio",
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/in-shelf.mp3",
        },
      }),
      _c("audio", {
        ref: "audioScaned",
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/re-scaned.mp3",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }